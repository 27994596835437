// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.meta-links-container {
    .label-container {
        margin-bottom: 0.75rem;
        text-transform: uppercase;
        font-size: 0.95rem;
        font-weight: 700;
        color: var(--primary-foreground-color);
        opacity: 0.3;
    }

    .links-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .link-container {
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            margin-right: 0.75rem;
            margin-bottom: 0.75rem;
            padding: 0.4rem 1.25rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            border-radius: 2rem;
            border: var(--focus-outline-size) solid transparent;
            font-size: 1rem;
            font-weight: 500;
            color: var(--primary-foreground-color);
            background-color: var(--overlay-color);
            backdrop-filter: blur(5px);

            &:hover, &:focus {
                background-color: @color-surface-light5-30;
            }

            &:focus {
                outline: none;
                border-color: @color-surface-light5;
            }
        }
    }
}