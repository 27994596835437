// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~stremio/common/screen-sizes.less';

.volume-change-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    background-color: var(--overlay-color);
    border-radius: var(--border-radius);
    padding: 2rem 2.5rem;
    gap: 1rem;

    .volume-icon {
        width: 6.5rem;
        height: 6.5rem;
        color: var(--primary-foreground-color);
    }

    .volume-slider {
        border-radius: var(--border-radius);
        max-height: 1rem;
        width: 6.5rem;
        max-height: 1rem;
        flex: 0 1 10rem;
        margin: 0 1rem;

        --track-size: 0.35rem;
        --thumb-size: 1rem;
    }

}

@media only screen and (max-width: @minimum) {
    .volume-change-indicator {
        padding: 1rem 1.5rem;
        
        .volume-icon {
            width: 4rem;
            height: 4rem;
        }

        .volume-slider {
            width: 4rem;
        }
    }
}