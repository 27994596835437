// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

:import('~stremio/common/Checkbox/styles.less') {
    checkbox-icon: icon;
}

.consent-checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0;
    border-radius: var(--border-radius);

    &:focus {
        outline: none;
        background-color: var(--overlay-color);
    }

    &:global(.checked) {
        .label {
            opacity: 1;
        }
    }

    .label {
        flex: 1;
        margin-left: 1rem;
        font-size: 0.9rem;
        color: var(--primary-foreground-color);
        opacity: 0.6;

        .link {
            font-size: 0.9rem;
            color: var(--primary-accent-color);

            &:hover {
                text-decoration: underline;
            }
        }
    }
}