// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1.5em;
    margin-bottom: 0.5rem;
    border-radius: var(--border-radius);
    
    &:global(.selected) {
        background-color: var(--overlay-color);

        .icon {
            display: block;
        }
    }

    &:hover, &:focus {
        background-color: var(--overlay-color);
    }

    .label {
        flex: 1;
        font-weight: 400;
        color: var(--primary-foreground-color);
    }

    .icon {
        flex: none;
        display: none;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 100%;
        margin-left: 1rem;
        background-color: var(--secondary-accent-color);
    }
}