// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.speed-menu-container {
    width: 14rem;
    overflow: visible !important;

    .title {
        flex: none;
        align-self: stretch;
        font-weight: 700;
        color: var(--primary-foreground-color);
        padding: 1.5rem 2rem;
    }

    .options-container {
        flex: 0 1 auto;
        max-height: calc(3.2rem * 8);
        padding: 0 1rem 0.5rem;
        overflow-y: auto;
        
        .option {
            height: 3.2rem;
        }
    }
}