// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

:import('~stremio/common/Multiselect/styles.less') {
    multiselect-menu-container: menu-container;
}

:import('~stremio/common/ModalDialog/styles.less') {
    selectable-inputs-modal-container: modal-dialog-container;
    selectable-inputs-modal-content: modal-dialog-content;
}

.library-container {
    width: 100%;
    height: 100%;
    background-color: transparent;

    .library-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .selectable-inputs-container {
            flex: none;
            align-self: stretch;
            display: flex;
            flex-direction: row;
            padding: 1.5rem;
            overflow: visible;

            .select-input-container {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: 15rem;
                height: 2.75rem;

                &:not(:last-child) {
                    margin-right: 1.5rem;
                }

                .multiselect-menu-container {
                    max-height: calc(3.2rem * 7);
                    overflow: auto;
                }
            }
        }

        .message-container {
            flex: 0 1 auto;
            align-self: stretch;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 1.5rem;
            overflow-y: auto;

            &:first-child {
                padding: 4rem;
            }

            &.no-user-message-container {
                .login-button-container {
                    flex: none;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 20rem;
                    height: 3.5rem;
                    border-radius: 3.5rem;
                    padding: 0.5rem 1rem;
                    margin-bottom: 1rem;
                    background-color: var(--secondary-accent-color);

                    &:hover {
                        outline: var(--focus-outline-size) solid var(--secondary-accent-color);
                        background-color: transparent;
                    }

                    .label {
                        flex-grow: 0;
                        flex-shrink: 1;
                        flex-basis: auto;
                        max-height: 4.8em;
                        font-size: 1.2rem;
                        font-weight: 700;
                        color: var(--primary-foreground-color);
                        text-align: center;
                    }
                }
            }

            .image {
                flex: none;
                width: 12rem;
                height: 12rem;
                margin-bottom: 2rem;
                object-fit: contain;
                object-position: center;
                opacity: 0.9;
            }

            .message-label {
                flex: none;
                margin-bottom: 2rem;
                font-size: 2rem;
                font-weight: 400;
                text-align: center;
                color: var(--primary-foreground-color);
            }
        }

        .meta-items-container {
            flex: 1;
            align-self: stretch;
            display: grid;
            grid-auto-rows: max-content;
            align-items: center;
            grid-gap: 0.5rem;
            padding: 0 1.5rem;
            overflow-y: auto;
        }
    }
}

.selectable-inputs-modal {
    .selectable-inputs-modal-container {
        overflow: visible;

        .selectable-inputs-modal-content {
            overflow: visible;

            .select-input-container {
                height: 3.5rem;

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

@media only screen and (min-width: @large) {
    .library-container {
        .library-content {
            .meta-items-container {
                grid-template-columns: repeat(10, 1fr);
            }
        }
    }
}

@media only screen and (max-width: @large) {
    .library-container {
        .library-content {
            .meta-items-container {
                grid-template-columns: repeat(9, 1fr);
            }
        }
    }
}

@media only screen and (max-width: @normal) {
    .library-container {
        .library-content {
            .meta-items-container {
                grid-template-columns: repeat(8, 1fr);
            }
        }
    }
}

@media only screen and (max-width: @medium) {
    .library-container {
        .library-content {
            .meta-items-container {
                grid-template-columns: repeat(7, 1fr);
            }
        }
    }
}

@media only screen and (max-width: @small) {
    .library-container {
        .library-content {
            .meta-items-container {
                grid-template-columns: repeat(6, 1fr);
            }
        }
    }
}

@media only screen and (max-width: @xsmall) {
    .library-container {
        .library-content {
            .meta-items-container {
                grid-template-columns: repeat(5, 1fr);
            }
        }
    }
}

@media only screen and (max-width: @xxsmall) {
    .library-container {
        .library-content {
            .meta-items-container {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}

@media only screen and (max-width: @minimum) {
    .library-container {
        .library-content {
            .selectable-inputs-container {
                justify-content: space-between;
            }

            .meta-items-container {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}