// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

:import('~stremio/common/Multiselect/styles.less') {
    multiselect-menu-container: menu-container;
}

:import('~stremio/common/ModalDialog/styles.less') {
    share-modal-content: modal-dialog-content;
}

:import('~stremio/common/ModalDialog/styles.less') {
    addon-modal-content: modal-dialog-content;
    cancel-button-label: label;
}

:import('~stremio/common/ModalDialog/styles.less') {
    filters-modal-container: modal-dialog-container;
    filters-modal-content: modal-dialog-content;
}

.addons-container {
    width: 100%;
    height: 100%;
    background-color: transparent;

    .addons-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-self: stretch;

        .selectable-inputs-container {
            flex: none;
            align-self: stretch;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 1.5rem;
            overflow: visible;

            .add-button-container {
                flex: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 3rem;
                margin-right: 1.5rem;
                padding: 0 1.5rem;
                border-radius: 3rem;
                background-color: var(--secondary-accent-color);

                &:hover {
                    outline: var(--focus-outline-size) solid var(--secondary-accent-color);
                    background-color: transparent;
                }

                &:focus {
                    outline-color: var(--primary-foreground-color);
                }

                .icon {
                    flex: none;
                    width: 1.2rem;
                    height: 1.2rem;
                    margin-right: 0.5rem;
                    color: var(--primary-foreground-color);
                }

                .add-button-label {
                    flex-grow: 0;
                    flex-shrink: 1;
                    flex-basis: auto;
                    max-height: 2.4em;
                    font-size: 1rem;
                    font-weight: 700;
                    color: var(--primary-foreground-color);
                }
            }

            .select-input-container {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: 15rem;
                margin-right: 1.5rem;

                .multiselect-menu-container {
                    max-height: calc(3.2rem * 7);
                    overflow: auto;
                }
            }

            .spacing {
                flex: 1;
            }

            .search-bar {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: 18rem;
            }

            .filter-button {
                flex: none;
                display: none;
                align-items: center;
                justify-content: center;
                width: 3rem;
                height: 3rem;
                border-radius: var(--border-radius);
                background-color: var(--overlay-color);

                .filter-icon {
                    flex: none;
                    width: 1.4rem;
                    height: 1.4rem;
                    color: var(--primary-foreground-color);
                }
            }
        }

        .message-container {
            flex: 1;
            align-self: stretch;
            padding: 0 1.5rem;
            font-size: 2rem;
            color: var(--primary-foreground-color);
        }

        .addons-list-container {
            flex: 1;
            align-self: stretch;
            padding: 0 1.5rem;
            overflow-y: auto;

            .addon {
                margin-bottom: 1.5rem;
            }
        }
    }
}

.filters-modal {
    .filters-modal-container {
        overflow: visible;

        .filters-modal-content {
            display: flex;
            flex-direction: column;
            overflow: visible !important;

            .select-input-container {
                height: 3rem;

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

.add-addon-modal-container {
    .addon-modal-content {
        width: 30rem;

        .notice {
            margin-bottom: 1.5rem;
            font-size: 1rem;
            color: var(--primary-foreground-color);
        }

        .addon-url-input {
            width: 100%;
            padding: 1rem;
            color: var(--primary-foreground-color);
            border-radius: var(--border-radius);
            background-color: var(--overlay-color);
            outline: var(--focus-outline-size) solid var(--overlay-color);
            outline-offset: calc(-1 * var(--focus-outline-size));

            &:hover {
                outline-color: var(--primary-foreground-color);
            }

            &:focus {
                outline-color: var(--primary-foreground-color);
            }
        }
    }

    .cancel-button {
        background-color: transparent;
        opacity: 0.3;

        &:hover {
            outline: var(--focus-outline-size) solid var(--primary-foreground-color);
            opacity: 1;
        }

        &:focus {
            outline-color: var(--primary-foreground-color);
        }

        .cancel-button-label {
            color: var(--primary-foreground-color);
        }
    }
}

.share-modal-container {
    .share-modal-content {
        width: 30rem;

        .title-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .logo, .icon {
                float: left;
                width: 5rem;
                height: 5rem;
                margin-right: 1.5rem;
                padding: 0.5rem;
            }

            .logo {
                object-fit: contain;
                object-position: center;
            }

            .icon {
                color: var(--primary-foreground-color);
            }

            .name-container {
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: baseline;

                .name {
                    flex-grow: 0;
                    flex-shrink: 1;
                    flex-basis: auto;
                    margin-right: 0.5rem;
                    font-size: 1.6rem;
                    color: var(--primary-foreground-color);
                }

                .version {
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: auto;
                    margin-top: 0.5rem;
                    color: var(--primary-foreground-color);
                }
            }
        }

        .share-prompt-container {
            margin-top: 1rem;
        }
    }
}

@media only screen and (max-width: @minimum) {
    .addons-container {
        .addons-content {
            .selectable-inputs-container {
                .add-button-container {
                    z-index: 1;
                    position: fixed;
                    right: 0;
                    bottom: calc(3rem + var(--horizontal-nav-bar-size));
                }

                .select-input-container {
                    display: none;
                }

                .spacing {
                    display: none;
                }

                .search-bar {
                    flex-basis: 100%;
                    margin-right: 1rem;
                }

                .filter-button {
                    display: flex;
                }
            }
        }
    }

    .share-modal-container {
        .share-modal-content {
            width: auto;
        }
    }

    .add-addon-modal-container {
        .addon-modal-content {
            width: auto;

            .notice {
                margin-bottom: 1rem;
            }
        }
    }
}