// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

:import('~stremio/common/Popup/styles.less') {
    popup-menu-container: menu-container;
}

.label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2.75rem;
    padding: 0 1.5rem;
    border-radius: 2.75rem;
    background-color: var(--overlay-color);

    &:global(.active) {
        .icon {
            transform: rotate(180deg);
        }
    }

    >.label {
        flex: 1;
        max-height: 2.4em;
        font-weight: 500;
        color: var(--primary-foreground-color);
    }

    .icon {
        flex: none;
        width: 1rem;
        height: 1rem;
        margin-left: 1rem;
        color: var(--primary-foreground-color);
        opacity: 0.4;
    }

    .popup-menu-container {
        width: 100%;
    }
}

.modal-container, .popup-menu-container {
    .menu-container {
        .option-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 1rem;

            &:global(.selected) {
                .icon {
                    display: block;
                }
            }

            &:hover, &:focus {
                background-color: var(--overlay-color);
            }

            .label {
                flex: 1;
                max-height: 4.8em;
                color: var(--primary-foreground-color);
            }

            .icon {
                flex: none;
                display: none;
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 100%;
                margin-left: 1rem;
                background-color: var(--secondary-accent-color);
                opacity: 1;
            }
        }

        .no-options-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            background-color: @color-background;

            .label {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: auto;
                font-size: 1.2rem;
                font-weight: 500;
                text-align: center;
                color: @color-surface-light5-90;
            }
        }
    }
}