// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~stremio/common/screen-sizes.less';

:import('~stremio/common/ModalDialog/styles.less') {
    modal-dialog-content: modal-dialog-content;
    modal-dialog-container: modal-dialog-container;
}

.event-modal {
    backdrop-filter: blur(10px);

    .modal-dialog-container {
        overflow: visible;
        max-width: 45rem;

        .modal-dialog-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: visible;

            .modal-dialog-content {
                overflow-y: visible;
            }
    
            .image {
                width: 100%;
                height: 100%;
                margin-top: -10rem;
            }
        
            .info-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 2.5rem;
                padding: 1rem 4rem;
                margin-top: -7rem;
        
                .title-container {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    .title {
                        color: var(--primary-foreground-color);
                        font-size: 1.325rem;
                        text-align: center;
                        padding: 0 6rem;
                    }
        
                    .label {
                        color: var(--primary-foreground-color);
                        font-size: 1rem;
                        text-align: center;
                        opacity: 0.5;
                    }
                }
        
                .addon-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 0.5rem;

                    .icon {
                        height: 2rem;
                        width: 2rem;
                        color: var(--primary-accent-color);
                    }

                    .name {
                        color: var(--primary-foreground-color);
                    }
                }
        
                .action-button {
                    background-color: var(--primary-foreground-color);
                    border: 2px solid var(--primary-foreground-color);
                    padding: 0.8rem 2rem;
                    border-radius: 2rem;
        
                    .button-label {
                        color: var(--primary-accent-color);
                        font-size: 1rem;
                        font-weight: 700;
                    }
        
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: @minimum) {
        .modal-dialog-container {
            .modal-dialog-content {
                .image {
                    height: 125%;
                    width: 125%;
                }

                .info-container {
                    .title-container {
                        .title {
                            padding: 0rem;
                            font-size: 1rem;
                        }

                        .label {
                            font-size: 0.875rem;
                        }
                    }
                }
            }
        }
    }
}