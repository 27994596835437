// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

.addon-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1.5rem;
    border-radius: var(--border-radius);
    background-color: var(--overlay-color);
    cursor: inherit;

    .logo-container {
        flex: none;
        width: 8rem;
        height: 8rem;

        .logo {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0.5rem;
            object-fit: contain;
            object-position: center;
        }

        .icon {
            display: block;
            width: 100%;
            height: 100%;
            padding: 1rem;
            color: var(--primary-foreground-color);
        }
    }

    .info-container {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        padding: 0 0.5rem;

        .name-container {
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            padding: 0 0.5rem;
            max-height: 3.6em;
            font-size: 1.6rem;
            color: var(--primary-foreground-color);
        }

        .version-container {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
            margin-top: 0.5rem;
            padding: 0 0.5rem;
            max-height: 2.4em;
            color: var(--primary-foreground-color);
            opacity: 0.6;
        }

        .types-container {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 100%;
            margin-top: 0.5rem;
            padding: 0 0.5rem;
            max-height: 2.4em;
            color: var(--primary-foreground-color);
            text-transform: capitalize;
            opacity: 0.4;
        }

        .description-container {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 100%;
            margin-top: 0.5rem;
            padding: 0 0.5rem;
            color: var(--primary-foreground-color);
        }
    }

    .buttons-container {
        flex: none;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 17rem;

        .action-buttons-container {
            flex: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }

        .install-button-container, .configure-button-container, .uninstall-button-container, .share-button-container {
            flex: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            height: 3.5rem;
            padding: 0 1rem;
            border-radius: 4rem;

            .icon {
                flex: none;
                width: 2rem;
                height: 2rem;
            }

            .label {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: auto;
                max-height: 2.4em;
                font-size: 1.1rem;
                font-weight: 700;
                text-align: center;
            }
        }

        .install-button-container {
            background-color: var(--secondary-accent-color);

            &:hover {
                outline: var(--focus-outline-size) solid var(--secondary-accent-color);
                background-color: transparent;
            }

            .label {
                color: var(--primary-foreground-color)
            }
        }

        .configure-button-container {
            flex: none;
            background-color: var(--secondary-accent-color);

            &:hover {
                outline: var(--focus-outline-size) solid var(--secondary-accent-color);
                background-color: transparent;
            }

            .icon {
                color: var(--primary-foreground-color)
            }
        }

        .uninstall-button-container {
            outline-color: var(--primary-foreground-color);
            outline-style: solid;
            opacity: 0.3;

            &:hover {
                opacity: 1;
            }

            .label {
                color: var(--primary-foreground-color);
            }
        }

        .share-button-container {
            opacity: 0.9;

            &:hover {
                .label {
                    text-decoration: underline;
                }
            }

            .icon {
                height: 1.5rem;
                width: 1.5rem;
                color: var(--primary-foreground-color);
            }

            .label {
                color: var(--primary-foreground-color);
            }
        }
    }
}

@media only screen and (max-width: @minimum) {
    .addon-container {
        flex-wrap: wrap;

        .info-container {
            margin-left: 0.5rem;
            padding: 0;

            .name-container {
                max-height: none;
                font-size: 1.3rem;
            }
        }

        .buttons-container {
            flex: 0 1 100%;
            width: auto;
            margin-top: 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .share-button-container {
                flex: none;

                .icon {
                    margin-right: 0;
                }

                .label {
                    display: none;
                }
            }

            .install-button-container, .uninstall-button-container {
                flex-basis: 100%;
                margin-right: 1rem;
            }
        }
    }
}