// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.option-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 3.5rem;
    border-radius: var(--border-radius);
    padding: 0 1rem;
    margin-bottom: 0.5rem;

    &:last-child {
        margin-bottom: 0;
    }

    .icon {
        flex: none;
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 1rem;
        color: var(--primary-foreground-color);
    }

    .label {
        flex: 1;
        max-height: 2.4em;
        font-weight: 400;
        color: var(--primary-foreground-color);
    }

    &:hover {
        background-color: var(--overlay-color);
    }

    &:global(.disabled) {
        opacity: 0.5;
    }
}