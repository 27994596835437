/* Copyright (C) 2017-2023 Smart code 203358507 */

.routes-container {
    position: relative;
    z-index: 0;
}

.routes-container .route-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.routes-container .route-container:not(:last-child) {
    display: none;
}

.routes-container .route-container .route-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
}

.routes-container .route-container .modals-container {
    width: 0;
    height: 0;
}

.routes-container .route-container .modals-container .modal-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
}
