// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.discrete-input-container {
    &:global(.disabled) {
        .header {
            color: var(--primary-foreground-color);
        }

        .input-container {
            opacity: 0.4;
        }
    }

    .header {
        margin-bottom: 0.5rem;
        color: var(--primary-foreground-color);
        opacity: 0.6;
    }

    .input-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 3.5rem;
        background: var(--overlay-color);

        .button-container {
            flex: none;
            width: 3.5rem;
            height: 3.5rem;
            padding: 1rem;
            border-radius: 100%;
            background-color: var(--overlay-color);

            .icon {
                display: block;
                width: 100%;
                height: 100%;
                color: var(--primary-foreground-color);
            }
        }

        .option-label {
            flex: 1;
            font-weight: 500;
            text-align: center;
            color: var(--primary-foreground-color);
        }
    }
}