// Copyright (C) 2017-2024 Smart code 203358507

@border-radius: 2.75rem;

.multiselect-menu {
    position: relative;
    min-width: 8.5rem;
    overflow: visible;
    border-radius: @border-radius;

    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }

    .multiselect-button {
        color: var(--primary-foreground-color);
        padding: 0.75rem 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0 0.5rem;
        border-radius: @border-radius;

        .icon {
            width: 1rem;
            color: var(--primary-foreground-color);
            opacity: 0.6;

            &.open {
                transform: rotate(180deg);
            }
        }
    }

    &:hover {
        background-color: var(--overlay-color);
    }
}