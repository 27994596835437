// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 1);

    .error-label {
        flex: 0 1 auto;
        padding: 0 8rem;
        max-height: 4.8em;
        font-size: 2rem;
        color: var(--primary-foreground-color);
        text-align: center;
    }

    .error-sub {
        flex: 0 1 auto;
        padding: 0 2rem;
        max-height: 4.8em;
        font-size: 1.3rem;
        margin-top: 0.8rem;
        color: var(--primary-foreground-color);
        text-align: center;            
    }

    .playlist-button {
        flex: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 3.5rem;
        border-radius: 3.5rem;
        margin-top: 1.5rem;
        padding: 0 2rem;
        background-color: var(--secondary-accent-color);

        &:hover {
            outline: var(--focus-outline-size) solid var(--secondary-accent-color);
            background-color: transparent;
        }

        .icon {
            flex: none;
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 1rem;
            color: var(--primary-foreground-color);
        }

        .label {
            flex: 1;
            max-height: 2.4em;
            font-size: 1.1rem;
            font-weight: 500;
            color: var(--primary-foreground-color);
            text-align: center;
        }
    }
}