// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

:import('~stremio/common/MetaPreview/styles.less') {
    meta-info-container: meta-info-container;
}

:import('~stremio/common/ModalDialog/styles.less') {
    modal-dialog-container: modal-dialog-container;
    title-modal-container: title-container;
}

.metadetails-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .nav-bar {
        z-index: 1;
        flex: none;
        align-self: stretch;
    }

    .metadetails-content {
        flex: 1;
        align-self: stretch;
        position: relative;
        z-index: 0;
        display: flex;
        flex-direction: row;

        .vertical-nav-bar {
            --vertical-nav-bar-size: 6rem;
            flex: none;
        }

        .background-image-layer {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            background-color: var(--modal-background-color);

            .background-image {
                pointer-events: none; 
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top left;
                opacity: 0.3;
            }
        }

        .meta-message-container {
            align-self: stretch;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 2rem;
            width: 100%;
            height: 100%;

            .image {
                flex: none;
                width: 12rem;
                height: 12rem;
                max-width: 100%;
                margin-bottom: 1rem;
                object-fit: contain;
                object-position: center;
                opacity: 0.9;
            }

            .message-label {
                flex: none;
                align-self: stretch;
                font-size: 2rem;
                text-align: center;
                color: var(--primary-foreground-color);
            }
        }

        .meta-preview {
            flex: 0 1 auto;
            align-self: stretch;
            padding: 0 4rem 2rem 4rem;
        }

        .spacing {
            flex: 1;
        }

        .videos-list, .streams-list {
            flex: 0 0 30rem;
            align-self: stretch;
            border-radius: var(--border-radius) 0 0 var(--border-radius);
            background-color: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(15px);
        }
    }
}

.meta-extension-modal-container {
    .modal-dialog-container {
        width: 80%;
        height: 80%;

        .title-modal-container {
            max-height: 1.2em;
        }

        .meta-extension-modal-iframe {
            width: 100%;
            height: 100%;
        }
    }
}

@media only screen and (max-width: @minimum) {
    .metadetails-container {
        .metadetails-content {
            display: block;
            overflow-y: auto;

            .spacing {
                display: none;
            }

            .meta-preview {
                padding: 0 1.5rem 2rem;
            }

            .videos-list, .streams-list {
                border-radius: var(--border-radius) var(--border-radius) 0 0;
            }
        }
    }
}