// Copyright (C) 2017-2023 Smart code 203358507

.meta-preview-placeholder-container {
    display: flex;
    flex-direction: column;

    .meta-info-container {
        flex: 1;
        align-self: stretch;

        .logo-container {
            width: 20rem;
            height: 8rem;
            max-width: 100%;
            border-radius: var(--border-radius);
            background-color: var(--color-placeholder-background);
        }

        .duration-release-info-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 1rem 0;

            .duration-container {
                flex-basis: 5rem;
                height: 1.4rem;
                margin-right: 1rem;
                border-radius: var(--border-radius);
                background-color: var(--color-placeholder-background);
            }

            .release-info-container {
                flex-basis: 5rem;
                height: 1.4rem;
                border-radius: var(--border-radius);
                background-color: var(--color-placeholder-background);
            }
        }

        .genres-container {
            margin: 1rem 0;

            .genres-header-container {
                width: 6.5rem;
                height: 1.6rem;
                max-width: 100%;
                border-radius: var(--border-radius);
                background-color: var(--color-placeholder-background);
            }

            .genre-label-container {
                width: 10rem;
                height: 1.2rem;
                max-width: 100%;
                margin-top: 0.2rem;
                border-radius: var(--border-radius);
                background-color: var(--color-placeholder-background);
            }
        }
    }

    .action-buttons-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 4rem;
        margin-bottom: 1rem;
        border-radius: 4rem;
        background-color: var(--color-placeholder-background);
    }
}