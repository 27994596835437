// Copyright (C) 2017-2023 Smart code 203358507

.buffering-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .buffering-loader {
        flex: none;
        max-width: 15rem;
        max-height: 15rem;
        animation: fadeInOut 2s infinite; 
        display: block; 
        width: auto;
        height: auto; 
    }
    
}


@keyframes fadeInOut {
    0%   { opacity: 0.2; }
    50%  { opacity: 1; }
    100% { opacity: 0.2; }
}
