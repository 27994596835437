// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

.action-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 100%;
    background-color: var(--overlay-color);
    backdrop-filter: blur(5px);
    transition: background-color 0.1s ease-out;

    &:hover, &:focus {
        outline: var(--focus-outline-size) solid var(--primary-foreground-color);
        background-color: transparent;
    }

    .icon-container {
        flex: none;

        .icon {
            display: block;
            height: 1.75rem;
            width: 1.75rem;
            color: var(--primary-foreground-color);
            opacity: 0.9;
        }
    }

    .label-container {
        flex: none;
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: center;

        .label {
            flex: 1;
            font-size: 1rem;
            font-weight: 500;
            max-height: 2.4em;
            padding: 0 0.2rem;
            text-align: center;
            color: var(--primary-foreground-color);
            opacity: 0.9;
        }
    }
}

@media only screen and (max-width: @minimum) {
    .action-button-container {
        flex-direction: row;
        padding: 0 1rem;

        .icon-container {
            height: 2rem;
            width: 2rem;
        }
    }
}