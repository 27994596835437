// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

:import('~stremio/common/Multiselect/styles.less') {
    multiselect-menu-container: menu-container;
    multiselect-label: label;
    multiselect-icon: icon;
}

.streams-list-container {
    display: flex;
    flex-direction: column;

    .message-container {
        flex: 0 1 auto;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 1rem 0;
        overflow-y: auto;

        .image {
            flex: none;
            width: 10rem;
            height: 10rem;
            max-width: 100%;
            margin-bottom: 1rem;
            object-fit: contain;
            object-position: center;
            opacity: 0.9;
        }

        .label {
            flex: none;
            font-size: 1.4rem;
            text-align: center;
            color: var(--primary-foreground-color);
        }
    }

    .addons-loading-container {
        display: flex;
        z-index: 1;
        overflow: visible;
        margin: 2em 1em 0 1em;
        gap: 1em;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .addons-loading {
            color: var(--primary-foreground-color);
            font-size: 1rem;
        }

        .addons-loading-bar {
            width: 90%;
            height: 0.3em;
            border-radius: var(--border-radius);
            background-color: var(--primary-accent-color);
        }
    }

    .select-choices-wrapper {
        display: flex;
        align-items: center;
        z-index: 2;
        margin: 1em 1em 0 1em;
        gap: 0 0.5em;
        overflow: visible;

        .back-button-container {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.5em;
            padding: 1em;
            max-height: 3em;

            .icon {
                width: 1.5em;
                height: 1.5em;
                color: var(--primary-foreground-color);
                opacity: 0.6;
            }

            &:hover, &:global(.active) {
                background-color: var(--overlay-color);
                opacity: 1;
    
                .icon {
                    color: var(--primary-foreground-color);
                    opacity: 0.8;
                }
            }
        }

        .episode-title {
            min-width: 45%;
            color: var(--primary-foreground-color);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .select-input-container {
            min-width: 40%;
            flex: 0 0 auto;
            flex-grow: 1;
            background: none;

            &:hover, &:focus, &:global(.active) {
                background-color: var(--overlay-color);
            }
    
            & >.multiselect-label {
                color: var(--primary-foreground-color);
            }
    
            & >.multiselect-icon {
                color: var(--primary-foreground-color);
            }
    
            .multiselect-menu-container {
                max-height: calc(3.2rem * 7);
                overflow: auto;
            }
        }
    }

    .streams-container {
        flex: 1 1 auto;
        align-self: stretch;
        margin-top: 1rem;
        padding: 0 1rem;
        overflow-y: auto;
    }

    .install-button-container {
        flex: none;
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 1rem;
        padding: 1.5rem 1rem;
        border-radius: var(--border-radius);
        background-color: var(--secondary-accent-color);
        border-radius: 3rem;

        &:hover {
            outline: var(--focus-outline-size) solid var(--secondary-accent-color);
            background-color: transparent;
        }

        .icon {
            flex: none;
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
            color: var(--primary-foreground-color);
        }

        .label {
            flex: 0 1 auto;
            font-size: 1.5rem;
            font-weight: 700;
            max-height: 3.6em;
            text-align: center;
            color: var(--primary-foreground-color);
        }
    }
}

@media only screen and (max-width: @minimum) {
    .streams-list-container {
        overflow: visible;

        .streams-container {
            margin-top: 0;
            overflow: visible;
            scrollbar-color: @color-surface-light5-20 transparent;

            &::-webkit-scrollbar-thumb {
                background-color: @color-surface-light5-20;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }
        }
    }
}