// Copyright (C) 2017-2023 Smart code 203358507

.tooltip-placeholder {
    z-index: -1;
    visibility: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}