// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.background {
    fill: @color-surface-light5;
}

.icon {
    fill: @color-background-dark5;
}