// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

:import('~stremio/common/MetaRow/styles.less') {
    meta-item: meta-item;
}

:import('~stremio/common/MetaRow/MetaRowPlaceholder/styles.less') {
    meta-item-placeholder: meta-item;
}

.search-container {
    width: 100%;
    height: 100%;
    background-color: transparent;

    .search-content {
        width: 100%;
        height: 100%;
        overflow-y: auto;

        .search-row {
            margin: 4rem 2rem;
        }

        .search-hints-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: 100%;

            .search-hints-title-container {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 1rem;

                .search-hints-title {
                    flex-grow: 0;
                    flex-shrink: 1;
                    flex-basis: auto;
                    font-size: 1.6rem;
                    color: @color-surface-light5-90;
                    text-align: center;
                    opacity: 0.4;
                }
            }
    
            .search-hints-container {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: center;
                align-content: flex-start;
                flex-wrap: wrap;
                padding: 4rem;
                max-width: 50%;
                margin: 0 auto;
    
                .search-hint-container {
                    flex: 0 0 25%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 0 1rem;
                    margin-bottom: 4rem;
    
                    .icon {
                        flex: none;
                        width: 4rem;
                        height: 4rem;
                        margin-bottom: 2rem;
                        color: @color-surface-light5-90;
                        opacity: 0.4;
                    }
    
                    .label {
                        flex-grow: 0;
                        flex-shrink: 1;
                        flex-basis: auto;
                        font-size: 1.2rem;
                        color: @color-surface-light5-90;
                        text-align: center;
                        opacity: 0.4;
                    }
                }
            }  
        }

        .message-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 4rem;

            .image {
                flex: none;
                width: 12rem;
                height: 12rem;
                margin-bottom: 1rem;
                object-fit: contain;
                object-position: center;
                opacity: 0.9;
            }

            .message-label {
                flex: 0 1 auto;
                font-size: 2.5rem;
                text-align: center;
                color: @color-secondaryvariant2-light1-90;
            }
        }
    }
}

@media only screen and (max-width: @large) {
    .search-container {
        .search-content {
            .search-row-poster, .search-row-square {
                .meta-item, .meta-item-placeholder {
                    &:nth-child(n+10) {
                        display: none;
                    }
                }
            }

            .search-row-landscape {
                .meta-item, .meta-item-placeholder {
                    &:nth-child(n+9) {
                        display: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @normal) {
    .search-container {
        .search-content {
            .search-row-poster, .search-row-square {
                .meta-item, .meta-item-placeholder {
                    &:nth-child(n+9) {
                        display: none;
                    }
                }
            }

            .search-row-landscape {
                .meta-item, .meta-item-placeholder {
                    &:nth-child(n+8) {
                        display: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @medium) {
    .search-container {
        .search-content {
            .search-hints-wrapper {
                .search-hints-container {
                    max-width: 70%;
                }
            }
            .search-row-poster, .search-row-square {
                .meta-item, .meta-item-placeholder {
                    &:nth-child(n+8) {
                        display: none;
                    }
                }
            }

            .search-row-landscape {
                .meta-item, .meta-item-placeholder {
                    &:nth-child(n+7) {
                        display: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @small) {
    .search-container {
        .search-content {
            .search-hints-wrapper {
                .search-hints-container {
                    max-width: 90%;
                }
            }
            .search-row-poster, .search-row-square {
                .meta-item, .meta-item-placeholder {
                    &:nth-child(n+7) {
                        display: none;
                    }
                }
            }

            .search-row-landscape {
                .meta-item, .meta-item-placeholder {
                    &:nth-child(n+6) {
                        display: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @xsmall) {
    .search-container {
        .search-content {
            .search-row-poster, .search-row-square {
                .meta-item, .meta-item-placeholder {
                    &:nth-child(n+6) {
                        display: none;
                    }
                }
            }

            .search-row-landscape {
                .meta-item, .meta-item-placeholder {
                    &:nth-child(n+5) {
                        display: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @xxsmall) {
    .search-container {
        .search-content {
            .search-hints-wrapper {
                .search-hints-container {
                    max-width: 100%;
                    .search-hint-container {
                        flex: 0 0 50%;
                    }
                }
            }
            .search-row-poster, .search-row-square {
                .meta-item, .meta-item-placeholder {
                    &:nth-child(n+5) {
                        display: none;
                    }
                }
            }

            .search-row-landscape {
                .meta-item, .meta-item-placeholder {
                    &:nth-child(n+4) {
                        display: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @minimum) {
    .search-container {
        .search-content {
            .search-row {
                margin: 2rem 1rem;
            }

            .search-row-poster, .search-row-square {
                .meta-item, .meta-item-placeholder {
                    &:nth-child(n+4) {
                        display: none;
                    }
                }
            }

            .search-hints-wrapper {
                margin-top: 4rem;
                .search-hints-container {
                    padding: 4rem 2rem;
                    .search-hint-container {
                        padding: 0 1.5rem;
                    }
                }
            }
        }
    }
}