// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .error-image {
        flex: none;
        width: 12rem;
        height: 12rem;
        object-fit: contain;
        object-position: center;
        opacity: 0.9;
    }

    .error-message {
        flex: none;
        padding: 0 3rem;
        font-size: 2rem;
        max-height: 3.6em;
        text-align: center;
        color: var(--primary-foreground-color);
    }

    .buttons-container {
        flex: none;
        align-self: stretch;
        margin: 0 2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        margin-top: 1rem;

        .button-container {
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0 2.5rem;
            min-width: 8rem;
            height: 3.5rem;
            border-radius: 3.5rem;
            background-color: var(--overlay-color);

            &:hover {
                outline: var(--focus-outline-size) solid var(--primary-foreground-color);
                background-color: transparent;
            }

            &:active {
                outline: none;
            }

            &:global(.disabled) {
                opacity: 0.3;
            }

            .label {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: auto;
                max-height: 2.4em;
                font-size: 1.1rem;
                font-weight: 500;
                text-align: center;
                color: var(--primary-foreground-color);
            }
        }
    }
}